import httpService, { IResponse } from "services/HttpService";
import axios, { AxiosRequestConfig } from "axios";
import { Model, BrowseType, DroneManufacturer } from "models/Model";
import { getEnvVariables } from "../../../env";
import modelEnv from "../model.env";

export interface IVideoUploadData {
  model: Model;
  uploadUrl: string;
}

interface IVideoCheckData {
  fileName: string;
  fileSize: number;
  lastModified: number;
}

export type IUploadDataRecord = Record<string, IVideoUploadData>;

export class ModelInputEntity {
  id: number;
  videoCreatedAt: Date;
  videoSize: number;
  fileName: string;
  videoFileS3Name: string;
  vidUploaded: boolean;
  gpsLength: number;
  model: {
    id: string;
  };
}

interface IMatched {
  fileName: string;
  modelInput: ModelInputEntity;
}

interface IUnmatched {
  fileName: string;
  reason: MatchingFailureReason;
}

export enum MatchingFailureReason {
  DATA_NOT_FOUND = "DATA_NOT_FOUND",
  VIDEO_ALREADY_UPLOADED = "VIDEO_ALREADY_UPLOADED",
}

export interface IMatchResult {
  matched: IMatched[];
  unmatched: IUnmatched[];
}

export interface ICreateModelDTO {
  droneType?: string;
  droneManufacturer?: DroneManufacturer;
  videoSize?: number;
  name?: string;
  fileName?: string;
  external?: boolean;
  gpsLogs?: string;
  browseType: BrowseType;
}

interface IModelPatchData {
  archived?: boolean;
  vidUploaded?: boolean;
  publicKey?: string;
  shortDescription?: string;
  name?: string;
  tags?: string[];
}

export interface ModelCanUpload {
  currentCount?: number;
  maxCount?: number;
  canUpload: boolean;
}

export enum ModelTypeFilter {
  widebrowse = "widebrowse",
  skyebrowse = "skyebrowse",
}

export enum ModelResolutionFilter {
  ultra = "ultra",
}

interface GetModelsQueryParams {
  archived: boolean;
  tags: string[];
  status?: "Finished" | "";
  type?: ModelTypeFilter;
  resolution?: ModelResolutionFilter;
  startDate?: number;
  endDate?: number;
  name?: string;
  organizationId?: number;
}

const env = getEnvVariables();
const modelV3 = modelEnv.apiUrl;
const sharedModelV3 = `${env.API_V3_URL}v3/shared/models`;

export const getUploadModeVideoToAwsConfig = (
  awsSignedUrl: string,
  file: File,
  options: AxiosRequestConfig = {}
): AxiosRequestConfig => {
  return {
    baseURL: awsSignedUrl,
    headers: {
      "Content-Type": file.type,
    },
    method: "PUT",
    data: file,
    ...options,
  };
};

const getModels = (page = 0, queryParams: GetModelsQueryParams) => {
  const url = modelV3;
  const params: any = {
    page,
    tags: queryParams.tags,
    status: queryParams.status,
    type: queryParams.type,
    resolution: queryParams.resolution,
    startDate: queryParams.startDate,
    endDate: queryParams.endDate,
    name: queryParams.name,
    organizationId: queryParams.organizationId,
  };

  if (queryParams.archived) {
    params.archived = true;
  }

  return httpService.get(url, null, { params });
};

const getModelDetails = (id: string) => {
  const url = `${modelV3}/${id}`;
  return httpService.get(`${url}?type=user`);
};

const getModelThumbnails = (id: string) => {
  const url = `${modelV3}/${id}/thumbnails`;
  return httpService.get(url);
};

const getModelsUploadsVideosUrls = async (
  filesData: IVideoCheckData[]
): Promise<IMatchResult> =>
  new Promise((res, rej) => {
    const url = `${modelV3}/videos`;
    httpService
      .post(url, { filesData })
      .then((r: IResponse) => res(r.data))
      .catch(rej);
  });

const uploadModelVideoToAws = (awsSignedUrl: string, file: File) => {
  return axios.request(getUploadModeVideoToAwsConfig(awsSignedUrl, file));
};

const patchModel = (
  modelId: string,
  data: IModelPatchData
): Promise<IResponse> => {
  const url = `${modelV3}/${modelId}`;
  return httpService.patch(url, data);
};

const getSharedModel = (modelId: string): Promise<IResponse> => {
  const url = `${sharedModelV3}/${modelId}`;
  return httpService.get(url, null);
};

const getPublicModel = (publicKey: string): Promise<IResponse> => {
  const url = `${env.API_V3_URL}v3/public/models/${publicKey}`;
  return httpService.get(url);
};

const getEmergencyUploadUrl = (modelId: string) => {
  const url = `${modelV3}/${modelId}/video`;
  return httpService.post(url);
};

const createPart = (
  modelId: string,
  videoCreatedAt: number,
  videoSize: number,
  fileName: string
) => {
  const body = { videoCreatedAt, videoSize, fileName };
  const url = `${modelV3}/${modelId}/input`;
  return httpService.post(url, body);
};

const createModel = (dto: ICreateModelDTO) => {
  const url = modelV3;
  return httpService.post(url, dto);
};

const deleteModel = (modelId: string) => {
  const url = `${modelV3}/${modelId}`;
  return httpService.delete(url);
};

const canUpload = (): Promise<IResponse<ModelCanUpload>> => {
  const url = `${modelV3}/can-upload`;
  return httpService.get(url, null, {
    cache: false,
  });
};

const ModelService = {
  getSharedModel,
  getModelDetails,
  getModelThumbnails,
  getModels,
  getModelsUploadsVideosUrls,
  uploadModelVideoToAws,
  getUploadModeVideoToAwsConfig,
  patchModel,
  getPublicModel,
  getEmergencyUploadUrl,
  createPart,
  createModel,
  deleteModel,
  canUpload,
};

export default ModelService;
