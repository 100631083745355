import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import Box, { BoxAlignedCenter } from "views/shared/components/Box";
import { IAppContext } from "views/interfaces";
import Heading from "views/shared/typography/Heading";
import { trimStringIfToLong } from "utils/stringUtils";
import CommentsCountButtonContainer from "../../../containers/CommentsCountButtonContainer";
import { useTranslation } from "react-i18next";
import { ModelPageViewTypes } from "const/global";
import { ITypedModelType, ModelStatus } from "models/Model";
import useBreakpoint from "views/shared/hook/useBreakpointHook";
import breakpoint from "styled-components-breakpoint";
import withReportIssueModal from "../../../issue/hoc/withReportIssueModal";
import ModelViewTypeButton from "../../../components/ModelViewTypeButton";
import ReportIssueButton from "../../../layouts/ModelPageLayout/components/ReportIssueButton";
import DownloadAssetsButton from "../../../layouts/ModelPageLayout/components/DownloadAssetsButton";

export interface IModelDetailsRowPropTypes {
  model: {
    id: string;
    publicKey: string;
    name: string;
    status: ModelStatus;
    typedModels?: { type: ITypedModelType }[];
  };
  view?: ModelPageViewTypes;
  topContent?: ReactNode;
  showCommentsModule?: boolean;
  showDownloadButton?: boolean;
  onViewChange: (viewType: ModelPageViewTypes) => void;
  onDownload?: () => void;
}

const DesktopModelDetailsRow = styled(Box)`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.small};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  width: 100%;

  ${breakpoint(`md`)`
    flex-direction: row;
    height: 72px;
    padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.medium};
  `}
`;
const ModelDetailsLeftBox = styled(Box)`
  align-items: center;
  justify-content: flex-start;
`;
const ModelDetailsRightBox = styled(Box)`
  .btn {
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }
`;
const MobileHeadingRow = styled.div`
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium} 0;
`;
const MobileBtnRow = styled(BoxAlignedCenter)`
  width: 100%;
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};

  &,
  .view-btn {
    width: 100%;
  }

  .share-btn {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};

    .end-icon {
      margin-left: 0;
    }
  }
`;

const PublicModelPageHeader = ({
  showCommentsModule,
  showDownloadButton,
  onDownload,
  onViewChange,
  model,
  view,
}: IModelDetailsRowPropTypes) => {
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const breakpointChange = breakpoint("md");
  const downloadButtonVisible = showDownloadButton && breakpointChange;
  const modelFinished = model.status === ModelStatus.Finished;

  const heading = useMemo(
    () => (
      <Heading id="model-name-heading">
        {trimStringIfToLong(model.name, 60)}
      </Heading>
    ),
    [model.name]
  );

  const viewButton = useMemo(() => {
    return (
      <div className="md:mr-4">
        <ModelViewTypeButton
          disabledViews={{
            [ModelPageViewTypes.Ortho]:
              !modelFinished ||
              !model.typedModels.find(
                (typed) => typed.type === ITypedModelType.Orthophoto
              ),
            [ModelPageViewTypes.Model]: !modelFinished,
          }}
          onClick={onViewChange}
          currentView={view}
        />
      </div>
    );
  }, [modelFinished, model.typedModels, onViewChange, view]);

  const reportButton = withReportIssueModal(ReportIssueButton)({
    model,
    publicView: true,
  });

  if (breakpointChange) {
    return (
      <DesktopModelDetailsRow>
        <ModelDetailsLeftBox>
          {viewButton}
          {heading}
        </ModelDetailsLeftBox>
        <ModelDetailsRightBox>
          {reportButton}
          {downloadButtonVisible && (
            <DownloadAssetsButton onClick={onDownload} />
          )}
          {showCommentsModule && <CommentsCountButtonContainer />}
        </ModelDetailsRightBox>
      </DesktopModelDetailsRow>
    );
  }

  return (
    <DesktopModelDetailsRow>
      <MobileHeadingRow>{heading}</MobileHeadingRow>
      <MobileBtnRow>
        {downloadButtonVisible && <DownloadAssetsButton onClick={onDownload} />}
        {viewButton}
      </MobileBtnRow>
    </DesktopModelDetailsRow>
  );
};

export default PublicModelPageHeader;
