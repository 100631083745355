import React from "react";
import DeleteModelDialog, { useDeleteModelDialog } from "./DeleteDialog";
import { ModelAccessPolicy } from "../ModelAccessPolicy";
import useCurrentUser from "../../account/hooks/useCurrentUser";
import Button from "../../shared/buttons/Button";
import TrashIcon from "../../shared/Icons/TrashIcon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MODEL_ROUTES } from "../../routes";
import { useCanUploadContext } from "../hooks/useCanUpload";

interface ModelDeleteButtonProps extends RouteComponentProps {
  model: {
    id: string;
    name: string;
    ownerId: number;
  };
}

const ModelDeleteButton = ({ model, history }: ModelDeleteButtonProps) => {
  const canUploadContext = useCanUploadContext();
  const dialog = useDeleteModelDialog();
  const { user } = useCurrentUser();

  const onDeleted = async () => {
    await canUploadContext.fetch();
    dialog.close();
    history.push(MODEL_ROUTES.list);
  };

  if (!ModelAccessPolicy.canDelete(user, user.id === model.ownerId)) {
    return null;
  }

  return (
    <div className="model-delete-button">
      <DeleteModelDialog
        open={dialog.isOpen}
        onClose={dialog.close}
        onDeleted={onDeleted}
        model={model}
      />
      <Button
        id="model-share-button"
        className="share-btn right-row-btn"
        endIcon={<TrashIcon />}
        type="light"
        onClick={() => dialog.open(model)}
      ></Button>
    </div>
  );
};

export default withRouter(ModelDeleteButton);
