import { useTranslation } from "react-i18next";
import useSubscription from "../../../../account/hooks/useSubscription";
import Button from "../../../../shared/buttons/Button";
import SubscriptionInformationRow from "../../../../shared/components/SubscriptionInformationRow/SubscriptionInformationRow";
import { useSubscriptionPaymentDialog } from "../../../payments/useSubscriptionPaymentDialog";

const PromoBanner = () => {
  const { t } = useTranslation();
  const subscription = useSubscription();
  const subscriptionDialog = useSubscriptionPaymentDialog();
  const pricingDialog = subscriptionDialog.content;

  if (subscription.active) {
    return null;
  }

  return (
    <>
      {pricingDialog}
      <SubscriptionInformationRow
        iconColor="blue"
        color="blue"
        icon="clock"
        primaryText={`Get 30% off your annual subscription or 30% off your first two months with code "CHRISTMAS30"`}
        secondaryText="Special holiday offer valid until the end of the year"
        button={
          <Button
            className="w-max"
            color="primary"
            size="small"
            onClick={subscriptionDialog.openDialog}
          >
            {t("subscription.subscribeNowBtn")}
          </Button>
        }
      />
    </>
  );
};

export default PromoBanner;
