import { createContext, useContext, useState } from "react";
import ModelService, { ModelCanUpload } from "../services/ModelService";

interface UseCanUploadReturn {
  canUpload: boolean | undefined;
  currentModelsCount: number | undefined;
  maxModelsCount: number | undefined;
  loaded: boolean;
  fetch: () => void;
}

export const CanUploadContext = createContext<UseCanUploadReturn | null>(null);

const useCanUpload = (): UseCanUploadReturn => {
  const [data, setData] = useState<ModelCanUpload>();
  const [loaded, setLoaded] = useState(false);

  const fetch = () => {
    ModelService.canUpload().then((response) => {
      setData(response.data);
      setLoaded(true);
    });
  };

  return {
    canUpload: data?.canUpload,
    currentModelsCount: data?.currentCount,
    maxModelsCount: data?.maxCount,
    loaded,
    fetch,
  };
};

export const CanUploadProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const canUploadData = useCanUpload();

  return (
    <CanUploadContext.Provider value={canUploadData}>
      {children}
    </CanUploadContext.Provider>
  );
};

export const useCanUploadContext = () => {
  const context = useContext(CanUploadContext);

  if (context === undefined) {
    throw new Error(
      "useCanUploadContext must be used within a CanUploadProvider"
    );
  }
  return context;
};

export default useCanUpload;
