import styled from "styled-components";
import { IGlobalStore } from "../../../stores/GlobalStore";
import React, { ReactNode, useEffect } from "react";
import { inject, observer } from "mobx-react";
import breakpoint from "styled-components-breakpoint";
import classNames from "classnames";
import { IAppContext } from "../../interfaces";
import Navbar from "../components/Navbar";
import UploadVideosDialogContainer from "../../models/containers/UploadVideosDialog";
import VideosUploadsLoadersContainer from "../../models/containers/UploadsManager";
import { useModelWebSocket } from "../../models/hooks/web-socket/useModelWebSocket";
import useCurrentUser from "../../account/hooks/useCurrentUser";
import { CanUploadProvider } from "../../models/hooks/useCanUpload";

interface IPageWrapperProps {
  children: ReactNode;
  GlobalStore?: IGlobalStore;
}

const calculatePageTopPadding = (props) => {
  const [subscriptionBarHeight] =
    props.theme.skye.elementSizes.subscriptionInfoBarHeight.split("px");
  const [navbarHeight] = props.theme.skye.elementSizes.navbarHeight.split("px");
  return parseInt(subscriptionBarHeight, 10) + parseInt(navbarHeight, 10);
};

const PageWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(-168deg, #dadada, #b3b3b3);
  overflow: hidden;
  position: absolute;
  transition: background 0.3s ease;

  &.logged-in {
    background: #f0f0f0;
  }

  ${breakpoint("md")`
      padding-top: 0;
  `}

  &.with-subscription-bar {
    padding-top: ${calculatePageTopPadding}px;

    ${breakpoint("md")`
      padding-top: 0;
  `}
  }

  .video-tutorials-btn {
    svg {
      path {
        stroke: none;
        fill: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
      }
    }
  }
`;

const AuthorizedPageLayout = ({ GlobalStore, children }: IPageWrapperProps) => {
  const modelWSSubscriber = useModelWebSocket();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      modelWSSubscriber.subscribeToUserModelChanges(String(user.id));
    }
  }, []);

  return (
    <CanUploadProvider>
      <PageWrapperContainer
        id="content"
        className={classNames("loggedIn", {
          "with-subscription-bar": GlobalStore.subscriptionBarVisible,
        })}
      >
        <Navbar />
        {children}
        {/* {
          Global components
        } */}
        <UploadVideosDialogContainer />
        <VideosUploadsLoadersContainer />
      </PageWrapperContainer>
    </CanUploadProvider>
  );
};

export default inject("GlobalStore")(observer(AuthorizedPageLayout));
