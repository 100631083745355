import { memo, useState } from "react";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../../services/AnalyticService";
import { ModelType } from "../../../../interfaces";
import { IModelListActions, IModelListItem } from "./interface";
import ArchivedModelListElement from "./ModelListElement/ArchivedModelListElement";
import OwnedModelListElement from "./ModelListElement/OwnedModelListElement";
import ShareDialogContainer from "../../../containers/ShareDialogContainer";
import ArchiveDialogContainer, {
  ArchiveDialogType,
} from "../../../containers/ArchiveDialogContainer";
import OrganizationModelListElement from "./ModelListElement/OrganizationModelLIstElement";
import { useModelPayment } from "../../../payments/useModelPayment";
import { Model } from "../../../../../models/Model";
import DeleteModelDialog, {
  useDeleteModelDialog,
} from "../../../containers/DeleteDialog";
import { useCanUploadContext } from "../../../hooks/useCanUpload";

interface IOrganizationModelListProps extends IModelListActions {
  listType: ModelType;
  models: IModelListItem[];
}

const ListElementMap = {
  [ModelType.Owned]: OwnedModelListElement,
  [ModelType.Organization]: OrganizationModelListElement,
  [ModelType.Archived]: ArchivedModelListElement,
};

const listElementFactory = (listType: ModelType) => {
  const component = ListElementMap[listType];
  if (!component)
    throw new Error(`ModelList: listType ${listType} is not supported`);

  return component;
};

const OrganizationModelList = ({
  models,
  listType,
  onReload,
  ...props
}: IOrganizationModelListProps) => {
  const { openWidget } = useModelPayment();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const deleteDialog = useDeleteModelDialog();
  const canUploadContext = useCanUploadContext();

  const [modelToShare, setModelToShare] = useState<IModelListItem | null>(null);
  const [modelToArchive, setModelToArchive] = useState<IModelListItem | null>(
    null
  );

  const handleArchive = (model: IModelListItem) => {
    AnalyticService.event(AnalyticEventCategory.ModelListPage, `Archive model`);
    setModelToArchive(model);
    setArchiveDialogOpen(true);
  };

  const handleOpenShare = (model: IModelListItem) => {
    AnalyticService.event(
      AnalyticEventCategory.ModelListPage,
      `Share by email`
    );
    setModelToShare(model);
    setShareDialogOpen(true);
  };

  const handleArchiveDialogClose = () => {
    setModelToArchive(null);
    setArchiveDialogOpen(false);
  };

  const onUnlock = (selectedModel: IModelListItem) => (e: any) => {
    e.preventDefault();
    AnalyticService.event(AnalyticEventCategory.ModelListPage, `Unlock model`);
    openWidget(selectedModel.id, selectedModel.accessType);
  };

  const handleModelDeleted = async () => {
    await canUploadContext.fetch();
    handleDeleteDialogClose();
    onReload();
  };

  const handleDeleteDialogClose = () => {
    deleteDialog.close();
  };

  const handleDelete = (model: IModelListItem) => {
    deleteDialog.open(model);
  };

  return (
    <>
      {models.map((model, index) => {
        const Component = listElementFactory(listType);
        return (
          <Component
            key={model.id}
            model={model}
            onShare={handleOpenShare}
            onHide={handleArchive}
            onReveal={handleArchive}
            onUnlock={onUnlock(model)}
            onDelete={handleDelete}
            {...props}
          />
        );
      })}
      {!!modelToShare && (
        <ShareDialogContainer
          open={shareDialogOpen}
          modelId={modelToShare.id}
          modelName={modelToShare.name}
          modelPublicKey={modelToShare.publicKey}
          onClose={() => {
            setModelToShare(null);
            setShareDialogOpen(false);
          }}
          onModelChange={(model: Model) => {
            const selected = models.find((m) => m.id === model.id);
            selected.publicKey = model.publicKey;
            modelToShare.publicKey = model.publicKey;
            // setModelToShare(null);
            // setShareDialogOpen(false);
            // onReload();
          }}
        />
      )}
      {!!modelToArchive && (
        <ArchiveDialogContainer
          open={archiveDialogOpen}
          model={modelToArchive}
          onClose={handleArchiveDialogClose}
          onSuccess={() => {
            setModelToArchive(null);
            setArchiveDialogOpen(false);
            onReload();
          }}
          type={
            modelToArchive.archived
              ? ArchiveDialogType.Archive
              : ArchiveDialogType.Unarchive
          }
        />
      )}
      {!!deleteDialog.model && (
        <DeleteModelDialog
          open={deleteDialog.isOpen}
          model={deleteDialog.model}
          onClose={handleDeleteDialogClose}
          onDeleted={handleModelDeleted}
        />
      )}
    </>
  );
};

export default memo(OrganizationModelList);
