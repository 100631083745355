import React from "react";
import Button from "../../../../../shared/buttons/Button";
import Description from "../../../../../shared/typography/Description";
import { SmallDescription } from "../../../../../shared/typography/typography";
import { PRICING_PAGE_URL } from "../../../../../../const/global";

interface UploadLockedProps {
  onCancel: () => void;
  onUpgrade: () => void;
}

const UploadLockedTemplate = ({ onUpgrade, onCancel }: UploadLockedProps) => {
  return (
    <div className="upload-locked-template">
      <div style={{ backgroundImage: `url('/img/bg-dots.png')` }}>
        <div className="img-wrapper w-full flex justify-center mb-6">
          <img src="/img/upload-locked.png" alt="upload-locked" />
        </div>
      </div>
      <div className="text-center mb-8">
        <h3 className="text-lg font-semibold mb-2">Upgrade Needed!</h3>
        <Description>
          You've used up your current file upload and 3D model creation limit.
          Upgrade to Lite to continue creating 3D models or delete one of your
          previous models.
        </Description>
      </div>
      <div className="flex flex-col gap-y-2">
        <Button fullSize color="primary" onClick={onUpgrade}>
          Upgrade
        </Button>
        <Button fullSize type="bordered" color="dark" onClick={onCancel}>
          Cancel
        </Button>
      </div>
      <div className="pt-5 pb-3">
        <SmallDescription className="text-center">
          More details and all features?{" "}
          <a
            href={PRICING_PAGE_URL}
            className="font-bold underline text-text-primary"
          >
            View pricing page
          </a>
        </SmallDescription>
      </div>
    </div>
  );
};

export default UploadLockedTemplate;
